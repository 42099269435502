import React from 'react'
import PropTypes from 'prop-types'
import { Link, useStaticQuery, graphql } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'

import './MainLayout.css'

function MainLayout({ activeMenu, children }) {
  const data = useStaticQuery(graphql`
    {
      logo: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: TRACED_SVG, layout: FULL_WIDTH)
        }
      }
      logo_small: file(relativePath: { eq: "logo.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 250
            height: 142
            placeholder: TRACED_SVG
            layout: FIXED
          )
        }
      }
      home: file(relativePath: { eq: "menu/home.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 48
            height: 48
            placeholder: TRACED_SVG
            layout: FIXED
          )
        }
      }
      program: file(relativePath: { eq: "menu/program.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 48
            height: 48
            placeholder: TRACED_SVG
            layout: FIXED
          )
        }
      }
      gift: file(relativePath: { eq: "menu/gift.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 48
            height: 48
            placeholder: TRACED_SVG
            layout: FIXED
          )
        }
      }
      photos: file(relativePath: { eq: "menu/photos.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 48
            height: 48
            placeholder: TRACED_SVG
            layout: FIXED
          )
        }
      }
      contact: file(relativePath: { eq: "menu/contact.png" }) {
        childImageSharp {
          gatsbyImageData(
            width: 48
            height: 48
            placeholder: TRACED_SVG
            layout: FIXED
          )
        }
      }
    }
  `)

  return (
    <div className="site-container">
      <div className="back left">
        <div className="back-top-left" />
        <div className="back-mid-left" />
      </div>
      <div>
        <nav className="menu-computer-container">
          <div className="left-side">
            <Link to="/programme">
              <div
                className={`menu-item ${
                  activeMenu === 'program' ? 'active' : ''
                }`}
              >
                <span>Les infos utiles</span>
              </div>
            </Link>
            <Link to="/cadeau">
              <div
                className={`menu-item ${activeMenu === 'gift' ? 'active' : ''}`}
              >
                <span>Le bôoo cado</span>
              </div>
            </Link>
          </div>

          <div className="home-link-logo">
            <Link to="/">
              <GatsbyImage
                image={data.logo_small.childImageSharp.gatsbyImageData}
                alt="Logo Caro + Yohann"
              />
            </Link>
          </div>

          <div className="right-side">
            <Link to="/photos">
              <div
                className={`menu-item ${
                  activeMenu === 'photos' ? 'active' : ''
                }`}
              >
                <span>Les photos !</span>
              </div>
            </Link>
            <Link to="/contact">
              <div
                className={`menu-item ${
                  activeMenu === 'contact' ? 'active' : ''
                }`}
              >
                <span>Des questions ?</span>
              </div>
            </Link>
          </div>
        </nav>
        <nav className="menu-container">
          <div className="menu-logo">
            <GatsbyImage
              image={data.logo.childImageSharp.gatsbyImageData}
              alt="Logo Caro + Yohann"
            />
          </div>
          <Link to="/">
            <div
              className={`menu-item ${activeMenu === 'home' ? 'active' : ''}`}
            >
              <GatsbyImage
                image={data.home.childImageSharp.gatsbyImageData}
                alt="Dessin Petite fleur"
              />
              <span>Accueil</span>
            </div>
          </Link>
          <Link to="/programme">
            <div
              className={`menu-item ${
                activeMenu === 'program' ? 'active' : ''
              }`}
            >
              <GatsbyImage
                image={data.program.childImageSharp.gatsbyImageData}
                alt="Dessin calendrier"
              />
              <span>Les infos utiles</span>
            </div>
          </Link>
          <Link to="/cadeau">
            <div
              className={`menu-item ${activeMenu === 'gift' ? 'active' : ''}`}
            >
              <GatsbyImage
                image={data.gift.childImageSharp.gatsbyImageData}
                alt="Dessin cadeau"
              />
              <span>Le bô cado</span>
            </div>
          </Link>
          <Link to="/photos">
            <div
              className={`menu-item ${activeMenu === 'photos' ? 'active' : ''}`}
            >
              <GatsbyImage
                image={data.photos.childImageSharp.gatsbyImageData}
                alt="Dessin enveloppe ouverte"
              />
              <span>Les photos !</span>
            </div>
          </Link>
          <Link to="/contact">
            <div
              className={`menu-item ${
                activeMenu === 'contact' ? 'active' : ''
              }`}
            >
              <GatsbyImage
                image={data.contact.childImageSharp.gatsbyImageData}
                alt="Dessin avion en papier"
              />
              <span>Des questions ?</span>
            </div>
          </Link>
        </nav>
        <main className="main">{children}</main>
      </div>
      <div className="back right">
        <div className="back-top-right" />
        <div className="back-mid-right" />
      </div>
    </div>
  )
}

MainLayout.propTypes = {
  activeMenu: PropTypes.string.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
}

export default MainLayout
